<template>
  <div class="app-list-view">
    <div class="app-list-view--header">
      <div class="app-list-view--actions">
        <slot name="actions">
          <slot name="prefix-actions" />
          <v-btn v-if="enableCreate" color="primary" :to="createTo">
            {{ $t('btn.create') }}
          </v-btn>
          <v-btn
            v-if="enableDelete && selected.length"
            color="error"
            @click="onDelete(selected)"
          >
            {{ $t('btn.delete') }}
          </v-btn>
          <slot name="suffix-actions" />
        </slot>
      </div>

      <app-input
        name="search"
        label="Search..."
        :binds="{ singleLine: true, appendIcon: 'mdi-magnify' }"
        v-model="search"
      />
    </div>
    <app-widget :app="app" :model="model" :title="title">
      <app-table
        ref="table"
        :app="app"
        :model="model"
        :headers="headers"
        :search="search"
        :serverItems="serverItems"
        :clientItems="clientItems"
        :loading="loading"
        :server-items-length="serverItemsLength"
        :hide-edit="hideEdit"
        :hide-delete="hideDelete"
        :serverSide="serverSide"
        :show-group-by="showGroupBy"
        v-bind="binds"
        v-model="selected"
        @server="getServerItems"
        @client="getClientItems"
        @edit="onEdit"
        @link="onLink"
        @delete="onDelete"
        :hide-export-csv="hideExportCsv"
      >
        <template
          v-for="(_, name) in $scopedSlots"
          :slot="name"
          slot-scope="slotData"
        >
          <slot :name="name" v-bind="slotData" />
        </template>

        <slot />
      </app-table>
    </app-widget>
  </div>
</template>

<script>
import AppWidget from '@components/AppWidget'
import AppInput from '@components/AppInput'
import AppTable from '@components/AppTable'
import { mapGetters } from 'vuex'

export default {
  name: 'app-list-view',
  components: {
    AppWidget,
    AppInput,
    AppTable
  },
  props: {
    app: {
      type: String,
      required: true
    },
    model: {
      type: String,
      required: true
    },
    apiUrl: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    createTo: {
      type: Object
    },
    editTo: {
      type: Object
    },
    binds: {
      type: Object,
      default: () => ({})
    },
    hideCreate: {
      type: Boolean,
      default: false
    },
    hideEdit: {
      type: Boolean,
      default: false
    },
    hideDelete: {
      type: Boolean,
      default: false
    },
    query: {
      type: Object,
      default: () => ({})
    },
    serverSide: {
      type: Boolean,
      default: false
    },
    showGroupBy: {
      type: Boolean,
      default: false
    },
    hideExportCsv: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['permissions']),
    enableCreate() {
      return (
        !this.hideCreate &&
        this.permissions.includes(`${this.app}.add_${this.model}`)
      )
    },
    enableDelete() {
      return (
        !this.hideDelete &&
        this.permissions.includes(`${this.app}.delete_${this.model}`)
      )
    }
  },
  data() {
    return {
      search: '',
      selected: [],
      serverItems: [],
      clientItems: [],
      serverItemsLength: 0,
      loading: false
    }
  },
  methods: {
    getServerItems(options = null) {
      this.loading = true
      const offset = (options.page - 1) * options.itemsPerPage
      let ordering = options.sortBy
        .reduce(
          (acc, value, index) => [
            ...acc,
            `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
          ],
          []
        )
        .join(',')

      if (!ordering) {
        ordering = 'id'
      }

      this.$api({
        method: 'get',
        url: this.apiUrl,
        params: {
          limit: options.itemsPerPage,
          offset: offset,
          search: options.search,
          ordering: ordering,
          ...options.multiSearch,
          ...this.query
        },
        hideSuccessAlert: true
      }).then(({ data }) => {
        this.serverItemsLength = data.count
        this.serverItems = data.results
        this.clientItems = data.results
        this.loading = false
      })
    },
    getClientItems(multiSearch) {
      this.clientItems = this.serverItems.filter((item) => {
        return Object.entries(multiSearch).every(([key, value]) => {
          if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
            return item[key.slice(0, key.lastIndexOf('.'))][
              key.slice(key.lastIndexOf('.') + 1)
            ]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          } else {
            return item[key]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          }
        })
      })
    },
    onEdit(item) {
      if (this.editTo) {
        this.$router.push({
          ...this.editTo,
          params: { id: item.id }
        })
      }
    },
    onDelete(items) {
      this.loading = true
      this.$confirmDelete(
        items,
        () => {
          return this.$api({
            method: 'delete',
            url: this.apiUrl,
            data: {
              pks: items.map((value) => value.id)
            },
            hideSuccessAlert: true
          }).then(() => {
            this.selected = []
            this.$refs.table.onServer()
          })
        },
        undefined,
        this.model === 'auditplanheader' || this.model === 'auditheader'
      )
      this.loading = false
    },
    onLink(header, item) {
      let router = {
        name: header.value
      }
      const prepareRouter = header.link?.prepareRouter
      if (prepareRouter) {
        router = prepareRouter(item)
      }
      this.$router.push(router)
    }
  }
}
</script>

<style lang="scss">
.app-list-view {
  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &--actions {
    .v-btn:not(:last-child) {
      margin-right: 10px;
    }
  }
}
</style>
